import React, { useCallback, useEffect } from 'react';
import { ModalCloseButton } from './booking-form/modal-close-button';
import { Link } from '@remix-run/react';

interface ModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  children: React.ReactNode;
  shouldCloseOnOutsideClick?: boolean;
  shouldCloseOnEscKey?: boolean;
}

interface ResellerPolicyModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const CancellationAndRefundPolicy = () => {
  return (
    <div className="w-full">
      <h1 className="text-center font-serif text-2xl">
        camp67 - Cancellation and Refund Policy
      </h1>
      <div className="flex flex-col gap-3 mt-12">
        <strong>
          <p className="mt-2">1. Acceptance of Terms</p>
        </strong>
        <p className="mt-2">
          By using our Service, you confirm that you have read, understood, and agreed to
          these Terms, including any changes or modifications we may make.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3 overflow-x-auto">
        <strong>
          <p className="mt-2">2. Cancellation and Refunds Policy</p>
        </strong>
        <p className="mt-2">
          This policy outlines the terms regarding cancellations and refunds to ensure
          clarity and fairness for all our guests.
        </p>
        <ol className="list-[lower-alpha] px-8 py-2">
          <strong>
            <li>Cancellation initiated by the guest</li>
          </strong>
          <p className="mt-2">
            camp67 will consider any booking cancellation or refund requests made by the
            guests under the below listed conditions:
          </p>
          <ol className="list-[lower-roman] px-8 py-2">
            <li>
              all cancellations and/or refund requests must be made in writing to
              support@camp67.com providing details of the reasons for cancellation.
            </li>
            <li>
              all notices of cancellation must be made at least 30 days prior to the date
              of arrival.
            </li>
            <li>
              all cancellations requests will be processed within 5 working days from the
              date of receiving the request.
            </li>
            <li>
              all refunds will be processed within 10 business days from the date of
              cancellation.
            </li>
            <li>
              all refunds will be calculated as per the conditions listed in the table
              below.
            </li>
          </ol>
          <br />
          <table className="w-[70%] text-left border border-black">
            <thead className="bg-red-200">
              <th className="whitespace-wrap">Notice of Cancellation</th>
              <th>Refund amount</th>
              <th>Exclusions</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>
                    Up to 30<sup>th</sup> September 2024
                  </strong>
                </td>
                <td>100%</td>
                <td>GST paid</td>
              </tr>
              <tr>
                <td>
                  <strong>
                    Up to 30<sup>th</sup> November 2024
                  </strong>
                </td>
                <td>40%</td>
                <td>GST paid</td>
              </tr>
              <tr>
                <td>
                  <strong>
                    From 1<sup>st</sup> December 2024
                  </strong>
                </td>
                <td>0%</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>No show</strong>
                </td>
                <td>0%</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <p className="mt-2">
            camp67 management will resolve any disputes related to cancellations and
            refunds on a case-by-case basis to ensure fairness and customer satisfaction.
          </p>
          <br />
          <strong>
            <li>Cancellation initiated by camp67</li>
          </strong>
          <p className="mt-2">
            In rare instances, camp67 may need to cancel bookings due to unforeseen
            circumstances beyond the control of camp67. In such instances, camp67 will
            inform all affected guests in writing and provide a full refund of all money
            paid for the booking with the notification of cancellation.
          </p>
        </ol>
        <p className="mt-2">
          All refunds will be processed within 10 business days of cancellation.
        </p>
        <p className="mt-2">
          camp67 has a strict policy of providing any agreed refunds only to the original
          account/credit card used to make the booking payment. It is your responsibility
          to ensure that you have provided us with the correct bank account/credit card
          details used for making the booking. Refunds cannot be processed to any other
          account or card. Once the refunds have been processed, we will notify you via
          email confirming that.
        </p>
      </div>
    </div>
  );
};

export const PrivacyPolicy = () => {
  return (
    <div className="w-full">
      <h1 className="text-center font-serif text-2xl">camp67 - Privacy Policy</h1>
      <div className="flex flex-col gap-3 mt-12">
        <strong>
          <p className="mt-2" id="important">
            1. Important
          </p>
        </strong>
        <p className="mt-2">
          Welcome to https://camp67.com/ (“Website”), owned and operated by Sukrit
          Wellness Tours Private Limited. At https://camp67.com/, we are committed upon
          the highest standards for secure transactions and protection of your Personal
          Information and its privacy. It is important that you read our policy from time
          to time and seek any clarifications required. Please note that privacy policies
          are subject to change. We will inform you of any significant changes being made
          prior to its implementation subject to availability of your email address for
          communication.
        </p>
        <p className="mt-2">
          This Privacy Policy describes all the types of personal information that we
          collect from you. It also describes how we may use that information and with
          whom we may share it. Our Privacy Policy also describes the steps we take to
          protect the security of this information. Should you wish to discontinue getting
          email updates from us please confirm you decision through the link provided in
          the update emails sent by us.
        </p>
        <p className="mt-2">
          By using our website, you expressly consent to our use and disclosure of your
          personal information in accordance with this Privacy Policy. If you do not agree
          please do not use or access our Site. This Privacy Policy is incorporated into
          and subject to the Terms and Conditions.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="definitions">
            2. Definitions
          </p>
        </strong>
        <p className="mt-2">
          “User”, “You/you”, “Your/your” means any person who browses, accesses or uses
          Website;
        </p>
        <p className="mt-2">
          “Use” or “Using” means to browse, access, view, copy, order or otherwise benefit
          from using the Website;
        </p>
        <p className="mt-2">
          “Member” means you who has obtained the Services of Website.
        </p>
        <p className="mt-2">
          “Affiliate” means a person or entity that directly or indirectly through one or
          more intermediaries, controls or is controlled by, or is under common control
          with, our organisation.
        </p>
        <p className="mt-2">“Policy” means this Privacy Policy.</p>
        <p className="mt-2">
          “Services” means all the services as provided in Terms of Use from time to time.
        </p>
        <p className="mt-2">
          “Service Provider” means, including but not limited to, any person, institution,
          firm, association, us, and any other related party, who provides support in
          completion or facilitation of the Services provided by an organisation.
        </p>
        <p className="mt-2">“Website” means https://camp67.com/</p>
        <p className="mt-2">
          “We”, “us” and “our” means Sukrit Wellness Tours Private Limited, head quarter
          at P.O. – 2nd Floor, 61/62, Pocket C-5, Rohini Sector 11, Northwest Delhi,
          Delhi-110085, India
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="collection-of-information">
            3. Collection of Information
          </p>
        </strong>
        <p className="mt-2">
          We collect very basic information that is needed to complete a purchase or
          provide a refund. That includes your name, address, telephone number, , email
          address, descriptions of the items requested or purchased, the IP address and
          operating system of your computer. We may collect further information from you
          specific to the additional services we provide such as participating or hosting
          a Yagna e.g. date of birth, language preference, Gotra, family members’ detail
          (where required).
        </p>
        <p className="mt-2">
          It also includes the browser type and version being used by you. We will not
          sell, distribute, or lease your personal information to third parties.
        </p>
        <p className="mt-2">
          We use such technologies that also allows us to collect aggregate information
          that does not identify you personally but lets us know your technology usage
          like the Uniform Resource Locater, Internet Protocol address, your operating
          system and browser type, etc. We may collect, compile, store, publish, promote,
          report, or otherwise disclose or use any such aggregate information which does
          not personally identify you. If any such aggregate information is correlated to
          you, it will be protected like any other personally identifiable information as
          described under this Privacy Policy. Credit Card, Debit Card, and Banking
          Information camp67.com does not collect or store Credit Card or Debit Card or
          any of your banking information as it is directly transmitted through the
          payment gateway provider to the payment network or bank.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="data-hosting">
            4. Data Hosting
          </p>
        </strong>
        <p className="mt-2">
          The website is hosted with third party data hosting specialists. Information
          collected is kept on servers owned and managed by data hosting companies, which
          may be in India or overseas including the United States of America. The
          companies being used by camp67.com are PCI compliant and have data security
          processes in place. However, camp67.com is not liable for any data theft or
          breach of security at the data centres hosting the website and holding consumer
          data.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="registration">
            5. Registration
          </p>
        </strong>
        <p className="mt-2">
          You may register and create a member account with us at no cost. This is to
          provide you with regular updates, promotions or special offers offered by camp67
          from time to time. Our online registration form may require you to provide
          information such as your name, and email address.
        </p>
        <p className="mt-2">
          Your unique password is a critical component of our security system. It is your
          responsibility to protect it at all times. Do not share your password with any
          third parties. We recommend that you should change your password regularly and,
          where you feel it has been compromised, please change it immediately.,
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="how-we-use-your-personally-identifiable-information">
            6. How We Use Your Personally Identifiable Information
          </p>
        </strong>
        <p className="mt-2">
          Marketing Email: We may use the information shared by you online, to create and
          deliver to you emails such as our newsletters, surveys or other email messages
          containing the service and product information, any tips for Kumbh Mela, Yajna
          and Puja tips, promotions and offers. You may select the ‘Unsubscribe’ link on
          the email, if at any time you decide that you would not like to receive these
          emails.
        </p>
        <p className="mt-2">
          Text Messages: If you chose to leave your mobile phone number with us, you give
          consent to receiving text message/SMS alerts related to updates about our
          Products and Service, Kumbh Mela tips, Yajna &amp; Puja tips, or promotions on
          your mobile phone. Please note that we will never send you any unsolicited text
          message on your mobile phone. We do not charge any fee to receive any text
          messages from us. However, your mobile service provider may charge you for
          sending and/or receiving text messages and air-time, as well as any other
          standard applicable rates charged by your mobile service provider.
        </p>
        <p className="mt-2">
          Telephone calls: If you have chosen to leave your mobile phone number or
          landline number on camp67.com, you may receive phone calls containing product
          and service information, Kumbh Mela information and booking Yajna &amp; Puja
          events or promotions on your mobile phone or landline phone.
        </p>
        <p className="mt-2">
          Communications to Serve You: We may provide you with service-related
          announcements through emails, concerning the website or contact you regarding
          your Member service requests or your questions, if you have chosen to provide us
          with your contact information. For example, all registered users will receive a
          confirmation email. These types of communications are necessary to create that
          high level of Member service that camp67.com offers its Members.
        </p>
        <p className="mt-2">
          Employment: With respect to employment, if you provide your personal
          information, as given on your resume, we will treat such personal information as
          confidential and it will be considered just for current and future employment
          opportunities. We will use such information just to contact you with respect to
          employment with us or our affiliates.
        </p>
        <p className="mt-2">
          Customised Service: Your personal information can also be used by us to provide
          you with customised services and use of our website. For example, we may suggest
          services that would be of particular interest or benefit to you. Such customised
          information may be communicated to you by way of emails or WhatsApp messages,
          text messages/SMS and phone calls.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p
            className="mt-2"
            id="technologies-that-allow-us-to-customise-your-experience-on-camp67.com"
          >
            7. Technologies that Allow Us to Customise Your Experience on camp67.com
          </p>
        </strong>
        <p className="mt-2">
          We use various technologies to collect information relating to your visit to our
          website, such as the Uniform Resource Locater (URL) that referred you to our
          website, Internet Protocol address, browser type, browser language, the date and
          time of your request, etc. This enables us to enhance and customise your
          experience on our website. For example, we may collect the Internet Protocol
          (IP) address identifying your computer or device and indicating your geographic
          region for statistical purposes. In some instances, we may use these
          technologies in combination with the personally identifiable information you
          provide on the website. These technologies may provide a variety of information
          such as whether you have visited camp67.com before. They also may enable you to
          save your preferences. The technology is explained below.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="web-beacons-and-tracking-links">
            8. Web Beacons and Tracking Links
          </p>
        </strong>
        <p className="mt-2">
          Web Beacons (also called clear gifs and pixel tags), tracking links and/or
          similar technology consist of a few lines of code and are embedded on the pages
          of our website. They are often used in combination with Cookies and are often
          not visible to the user of the website. Web Beacons may relay information to
          third parties such as our service providers and may be used in order to track
          Member response to certain advertising, to better target interactive advertising
          and to enhance Member support and usability. If you reject our Cookies (see
          Cookie section below), you will prevent Web Beacons from relaying this
          information about you and may limit your use of some of our website features.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="cookies">
            9. Cookies
          </p>
        </strong>
        <p className="mt-2">
          Browser Cookies are bits of text that are placed on your computer’s hard drive
          when you visit our website. Flash Cookies are similar to Browser Cookies except
          that they can store data more complex than simple text and are stored in your
          browser rather than on your hard drive. You can have your browser and/or plugs
          to your browser, such as flash, notify you of, or automatically reject, Cookies.
          If you reject our Cookies or opt-out of the use of cookies, you may still use
          this website, but you may be limited in the use of some of its features.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="sharing-of-personal-information">
            10. Sharing of Personal Information
          </p>
        </strong>
        <p className="mt-2">
          We will not provide your personally identifiable information to third parties
          for their use in marketing their services to you without your consent, except as
          provided in the “Business Transfers” Section below. Except as described here, we
          do not sell or otherwise disclose personally identifiable information about our
          website visitors, to anyone.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="camp67.com-affiliates">
            11. camp67.com Affiliates
          </p>
        </strong>
        <p className="mt-2">
          We may share your personal information with our Affiliates that distribute and
          market our company’s products and services. Our Affiliates may use this
          information in accordance with this Privacy Policy including, if you have so
          chosen, sending you products and services information, Kumbh Mela tips, Yajna
          &amp; Puja tips, or promotions. If you prefer that we do not share your personal
          information with our Affiliates, please do not provide it to us. In that case,
          we are unable to offer the benefits of camp67.com to anyone who does not consent
          to the sharing of their personal information with our Affiliates.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="third-parties">
            12. Third Parties
          </p>
        </strong>
        <p className="mt-2">
          We may retain other companies and individuals to perform functions on our behalf
          consistent with this Privacy Policy. Examples include webstore management
          companies, order processing companies, social media marketing companies, courier
          companies, data analysis firms, Member support specialists, email vendors,
          web-hosting companies, and fulfilment companies (e.g., companies that coordinate
          mailings). Such third parties may be provided with access to personal
          information needed to perform their functions but may not use such information
          other than on our behalf and in accordance with this Privacy Policy. In
          addition, in some instances, you may be offered the opportunity to consent to
          the sharing of your information with a third party such as an event or promotion
          co-sponsor. If you consent, we will share your information with such third party
          and the information you provide may be used by such third party for their own
          purposes and in accordance with their own policies.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="business-transfers">
            13. Business Transfers
          </p>
        </strong>
        <p className="mt-2">
          While continuation and development of our business, we might sell certain of our
          assets. In such transactions, user information, including personal information,
          is one of the transferred business assets, and by submitting your personally
          identifiable information on the website, you agree that your data may be
          transferred to such parties in these circumstances. Compliance With Law
        </p>
        <p className="mt-2">
          We may disclose information that is necessary to comply with any applicable law,
          regulation, legal process, or governmental request. In addition, we may disclose
          any information when it is necessary to prevent physical harm or financial loss
          or in connection with suspected or actual illegal activity.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="transfer-of-data-to-other-countries">
            14. Transfer of Data to Other Countries
          </p>
        </strong>
        <p className="mt-2">
          camp67.com, our Affiliates and third parties who may receive your personal
          information in accordance with this Privacy Policy and the databases in which
          your personal information is stored shall be located in data hosting server and
          are required to honour the privacy representations made in this Privacy Policy
          under applicable laws of this country. In case of transfer of data to any other
          country, legal protections applicable to personal information in the concerned
          country will apply.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="children-privacy">
            15. Children privacy
          </p>
        </strong>
        <p className="mt-2">
          The camp67.com website is not designed to allow persons under the age of 12 to
          make any bookings at our site or access any products or services without the
          involvement of their parent or guardian or an adult responsible for them. We do
          not knowingly collect personally identifiable information from anyone under the
          age of 18. If you are under 18 years of age, you may browse camp67.com, but you
          cannot register on your own. If we become aware that we have inadvertently
          received personal information from a visitor under the age of 18 on the website,
          the information will be deleted from our records.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="how-we-protect-personal-information">
            16. How We Protect Personal Information
          </p>
        </strong>
        <p className="mt-2">
          We maintain reasonable safeguards for this website to protect against
          unauthorised disclosure, use, alteration, or destruction of the personal
          information you provide on camp67.com. If you have any questions about security
          at our Site, you can email us at support@camp67.com
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2" id="comments">
            17. Comments
          </p>
        </strong>
        <p className="mt-2">
          We have taken great efforts to ensure that your visit to camp67.com is an
          interesting and fruitful one. We also ensure that your privacy is constantly
          respected. If you have any questions, comments, or concerns about our privacy
          steps, please contact us by email at support@camp67.com Copyright @ Sukrit
          Wellness Tours Private Limited. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export const PaymentPolicy = () => {
  return (
    <div className="w-full">
      <h1 className="text-center font-serif text-2xl">camp67 - Payment Policy</h1>
      <div className="flex flex-col gap-3 mt-12">
        <strong>
          <p className="mt-2">1. Acceptance of Terms</p>
        </strong>
        <p className="mt-2">
          By using our Service, you confirm that you have read, understood, and agreed to
          these Terms, including any changes or modifications we may make.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">2. Payment Policy</p>
        </strong>
        <ol className="mt-2 pl-6">
          <li className="list-decimal">
            Maha Kumbh Mela 2025 package at camp67 can be booked online ONLY through its
            website https://camp67.com.
          </li>
          <li className="list-decimal">
            camp67 has a strict policy of not accepting any payments from any third party
            either online or offline.
          </li>
          <li className="list-decimal">
            Various packages for Maha Kumbh Mela 2025 at camp67 are mentioned on our
            official website https://camp67.com. Select the package(s), fill in your
            details (Name, phone number, email address, number of adults and children),
            dates of arrival and departure at camp67, and your preferred tent experience.
          </li>
          <li className="list-decimal">
            camp67 offers various tent experiences within our site for the convenience of
            guests who are visiting either on their own or in groups or those who are
            travelling with their family and friends and seeking privacy in their
            accommodation.
            <br />
            Our package prices have been calculated accordingly to cater to diverse needs
            of our guests. All tent experience package bookings must be made taking into
            consideration the following core aspects:
            <ol type="a" className="ml-3 my-2">
              <li className="list-[lower-alpha]">
                <strong>Dormitory (Single beds) &amp; Dormitory (Bunk beds)</strong>
              </li>
              <ol type="i" className="ml-3 my-2">
                <li className="list-[lower-roman]">
                  Comprises of 8 Single beds/8 Bunk beds in one large tent.
                </li>
                <li className="list-[lower-roman]">
                  Booking must be on a per bed basis as per the package prices listed on
                  the website.
                </li>
              </ol>
              <li className="list-[lower-alpha]">
                <strong>Dormitory (Twin room)</strong>
              </li>
              <ol type="i" className="ml-3">
                <li className="list-[lower-roman]">
                  Comprises of fully enclosed private rooms within a large tent.
                </li>
                <li className="list-[lower-roman]">
                  It includes 2 Single beds OR 1 Bunk bed in each room together with the
                  amenities displayed on our website.
                </li>
                <li className="list-[lower-roman]">
                  Booking must be made for two (2) beds as per the package prices listed
                  on the website.
                </li>
              </ol>
              <i>*Maximum permissible occupancy in these rooms will be 2 persons.</i>
              <li className="list-[lower-alpha]">
                <strong>Dormitory (Family room)</strong>
              </li>
              <ol type="i" className="ml-3">
                <li className="list-[lower-roman]">
                  Comprises of fully enclosed private rooms within a large tent.
                </li>
                <li className="list-[lower-roman]">
                  It includes 2 Bunk beds OR 1 Queen Bunk bed + 1 Single bed together with
                  the amenities displayed on our website.
                </li>
                <li className="list-[lower-roman]">
                  Booking must be made for two (4) beds as per the package prices listed
                  on the website.
                </li>
              </ol>
              <i>*Maximum permissible occupancy in these rooms will be 4 persons.</i>
              <li className="list-[lower-alpha]">
                <strong>Dormitory (Family room plus +)</strong>
              </li>
              <ol type="i" className="ml-3">
                <li className="list-[lower-roman]">
                  Comprises of fully enclosed private rooms within a large tent.
                </li>
                <li className="list-[lower-roman]">
                  It includes 2 Queen Bunk bed in each room together with the amenities
                  displayed on our website.
                </li>
                <li className="list-[lower-roman]">
                  Booking must be made for two (6) beds as per the package prices listed
                  on the website.
                </li>
              </ol>
              <i>*Maximum permissible occupancy in these rooms will be 6 persons.</i>
            </ol>
            It is the responsibility of the guest to book the most appropriate tent
            experience package as per their individual requirements. Irrespective of any
            changes in the number of individuals, the tent experience price for b, c and d
            packages listed above will be payable in full, based on the maximum
            permissible individuals (including children) staying in the relevant room.
          </li>
          <li className="list-decimal">
            All payment to be made in the name of ‘Sukrit Wellness Tours Private Limited’.
            On payment of the full amount, you will be given an official receipt from
            camp67.com confirming your booking.
          </li>
          <li className="list-decimal">
            The payment amount is refundable in accordance with our Cancellations and
            Refunds Policy in the event of your cancellation or due to changes in travel
            dates.
          </li>
          <li className="list-decimal">
            All bookings of your preferred tent experience is non-transferable.
          </li>
          <li className="list-decimal">
            In such cases where you are unable to make payment online through the website
            https://camp67.com, please contact customer support by email
            support@camp67.com or by call or text/sms or WhatsApp at +91 8860092457.
          </li>
          <li className="list-decimal">
            EMI option is not available for any Online booking of the package.
          </li>
        </ol>
      </div>
    </div>
  );
};

export const TermsOfService = () => {
  return (
    <div className="w-full">
      <h1 className="text-center font-serif text-2xl">camp67 - Terms of Service</h1>
      <div className="flex flex-col gap-3 mt-12">
        <strong>
          <p className="mt-2">1. Acceptance of Terms</p>
        </strong>
        <p className="mt-2">
          By using our Service, you confirm that you have read, understood, and agreed to
          these Terms, including any changes or modifications we may make.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">2. Services Offered</p>
        </strong>
        <p className="mt-2">
          camp67 offers various accommodation options for visitors of the Kumbh Mela,
          along with amenities such as food services, yoga sessions, and more. These
          services are subject to availability and may change.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">3. Privacy Policy</p>
        </strong>
        <p className="mt-2">
          Our{' '}
          <Link prefetch="intent" to="/privacy-policy" className="underline">
            Privacy Policy
          </Link>{' '}
          describes how we collect, use, protect, and share your personal information. It
          is important that you go through the Privacy Policy emailed to you and seek any
          clarifications required by writing to us on support@camp67.com. By agreeing to
          these Terms, you also agree to the terms of our Privacy Policy.
        </p>
        <p className="mt-2">
          camp67 respects the privacy of all guests and is committed to protecting the
          personal information you share with us. This policy describes how we collect,
          use, disclose, and safeguard your data.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">4. User Responsibilities</p>
        </strong>
        <p className="mt-2">
          Users are expected to provide accurate and current information when making a
          booking. Comply with local laws and respect the community and environment during
          their stay. Use our services and facilities responsibly and respectfully.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">5. Booking Policy and Conditions</p>
        </strong>
        <p className="mt-2">
          Your booking is confirmed, and a contract exists when camp67 issues a written
          confirmation of booking after receipt of the applicable deposit amount. Please
          check your confirmation carefully and report any incorrect or incomplete
          information to our team immediately.
          <br />
          <br />
          <strong>
            camp67 has a strict policy of not accepting any bookings from any third party.
            All bookings must be made directly by the guests through our online booking
            portal link available on our website.
          </strong>
          <br />
          <br />
          Guests must ensure that the names mentioned in your booking are exactly as
          stated in the valid identity proof document i.e. Passport (for all international
          guests), PAN/Aadhar Card (for all Indian residents). Please note, at the time of
          your check-in, you will be required to present the Credit Card/ Debit Card used
          for making the booking to authenticate your booking.
        </p>
        <ol className="list-[lower-alpha] px-8 py-2">
          <strong>
            <li>Arrivals and Departure</li>
          </strong>
          <p className="mt-2">
            Our standard Check in time is 1400 hrs. and check out time is 1200 hrs.
          </p>
          <ol className="list-[lower-roman] px-8 py-2">
            <strong>
              <li>Early arrival:</li>
            </strong>
            <p className="mt-2">
              If you arrive over 2 hours prior to the scheduled check-in time, we cannot
              guarantee availability of your room, unless prior arrangements have been
              made with us at the time of booking or at least four weeks in advance. Any
              room booking more than two hours prior to the scheduled check-in time will
              be charged as an additional day&apos;s tariff at the standard rate
              applicable on the day. These requests will only be entertained subject to
              availability of the requested room.
            </p>
            <strong>
              <li>Delayed arrival:</li>
            </strong>
            <p className="mt-2">
              If you do not arrive over 4 hours from your scheduled check-in time and have
              failed to notify us, your booking for the day will be considered a “no-show”
              and it will be made available to other prospective customer; unless prior
              arrangements have been made with us at the time of booking or at least four
              weeks in advance. We acknowledge there may be circumstances beyond your
              control and recommend our team is informed about it by calling on the direct
              line +91 8860092457 or writing on support@camp67.com.
            </p>
            <strong>
              <li>Delayed departure</li>
            </strong>
            <p className="mt-2">
              If you fail to vacate the room as per the scheduled check-out time and
              delayed over one hour beyond the check-out time, your departure will be
              considered a “delayed departure” unless prior arrangements have been made
              with us at the time of booking or at least four weeks in advance. This
              measure is undertaken in the interest of arriving passengers, and we
              apologize for any inconvenience. Any request for staying longer will be
              considered as an additional day&apos;s booking and will be considered
              subject to its availability and will be charged at the standard rate
              applicable on the day. Please contact the camp67 management or reception
              desk should this be required.
            </p>
          </ol>
          <strong>
            <li>No-Show</li>
          </strong>
          <p className="mt-2">
            In an event of guests not turning up at the camp site up to 4 hours after the
            scheduled check-in time, it will be considered as a no show. camp67 shall not
            be under any obligation to refund the booking amount.
          </p>
          <strong>
            <li>Payment</li>
          </strong>
          <p className="mt-2">
            All payment for your booking must be made directly through the secure online
            payment system available on our website. See our{' '}
            <Link prefetch="intent" to="/payment-policy" className="underline">
              Payment Policy
            </Link>{' '}
            for more information.
          </p>
        </ol>
      </div>
      <div className="flex flex-col gap-3 mt-3 overflow-x-auto">
        <strong>
          <p className="mt-2">6. Cancellation and Refunds Policy</p>
        </strong>
        <p className="mt-2">
          This policy outlines the terms regarding cancellations and refunds to ensure
          clarity and fairness for all our guests.
        </p>
        <ol className="list-[lower-alpha] px-8 py-2">
          <strong>
            <li>Cancellation initiated by the guest</li>
          </strong>
          <p className="mt-2">
            camp67 will consider any booking cancellation or refund requests made by the
            guests under the below listed conditions:
          </p>
          <ol className="list-[lower-roman] px-8 py-2">
            <li>
              all cancellations and/or refund requests must be made in writing to
              support@camp67.com providing details of the reasons for cancellation.
            </li>
            <li>
              all notices of cancellation must be made at least 30 days prior to the date
              of arrival.
            </li>
            <li>
              all cancellations requests will be processed within 5 working days from the
              date of receiving the request.
            </li>
            <li>
              all refunds will be processed within 10 business days from the date of
              cancellation.
            </li>
            <li>
              all refunds will be calculated as per the conditions listed in the table
              below.
            </li>
          </ol>
          <br />
          <table className="w-[70%] text-left border border-black">
            <thead className="bg-red-200">
              <th className="whitespace-wrap">Notice of Cancellation</th>
              <th>Refund amount</th>
              <th>Exclusions</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>
                    Up to 30<sup>th</sup> September 2024
                  </strong>
                </td>
                <td>100%</td>
                <td>GST paid</td>
              </tr>
              <tr>
                <td>
                  <strong>
                    Up to 30<sup>th</sup> November 2024
                  </strong>
                </td>
                <td>40%</td>
                <td>GST paid</td>
              </tr>
              <tr>
                <td>
                  <strong>
                    From 1<sup>st</sup> December 2024
                  </strong>
                </td>
                <td>0%</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>No show</strong>
                </td>
                <td>0%</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <p className="mt-2">
            camp67 management will resolve any disputes related to cancellations and
            refunds on a case-by-case basis to ensure fairness and customer satisfaction.
          </p>
          <br />
          <strong>
            <li>Cancellation initiated by camp67</li>
          </strong>
          <p className="mt-2">
            In rare instances, camp67 may need to cancel bookings due to unforeseen
            circumstances beyond the control of camp67. In such instances, camp67 will
            inform all affected guests in writing and provide a full refund of all money
            paid for the booking with the notification of cancellation.
          </p>
        </ol>
        <p className="mt-2">
          All refunds will be processed within 10 business days of cancellation.
        </p>
        <p className="mt-2">
          camp67 has a strict policy of providing any agreed refunds only to the original
          account/credit card used to make the booking payment. It is your responsibility
          to ensure that you have provided us with the correct bank account/credit card
          details used for making the booking. Refunds cannot be processed to any other
          account or card. Once the refunds have been processed, we will notify you via
          email confirming that.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">7. camp67 Terms of Service</p>
        </strong>
        <ol className="list-[lower-roman] px-8 py-2">
          <strong>
            <li>Registration</li>
          </strong>
          <p className="mt-2">
            Upon arrival, all guests are required to register at our Welcome Desk and our
            friendly staff will assist you in explaining the Camp facilities and guide you
            to your room. In accordance with the local laws, it is mandatory for all
            guests to produce a Valid Identity proof/ Passport at the time of check-in.
          </p>
          <strong>
            <li>Accessing Services available at the site:</li>
          </strong>
          <p className="mt-2">
            At camp67 you have access to a range of complementary services and additional
            services you may opt for. Please ask our Camp staff for details and if you are
            interested to opt for any of those services.
          </p>
          <strong>
            <li>Visitors</li>
          </strong>
          <p className="mt-2">
            For the safety and security of our guests, camp67 has a stringent policy in
            place for restricting access to any third party within the camp67 premises.
            All Visitors are required to register at the Welcome Desk before entering the
            site. Should you have any visitor seeking to meet you, we strongly advise that
            you provide their information to the camp staff in advance.
          </p>
          <strong>
            <li>Restaurants and dining</li>
          </strong>
          <p className="mt-2">
            At camp67, we offer all three meals - breakfast, lunch and dinner as part of
            our package. All meals will be served in the dining room. Our Buffet will
            offer a wide selection of dishes for breakfast, lunch and dinner. camp67
            management shall endeavour to meet your specific dietary requirements subject
            to the availability of desired products and at the discretion of management.
          </p>
          <p className="mt-2">
            Additionally, camp67 guests will also have access to our 24 hours Coffee Shop
            where you can buy from a wide variety of food and beverage options available.
          </p>
          <strong>
            <li>Personal belongings</li>
          </strong>
          <p className="mt-2">
            camp67 provides you a safe and secure environment for our guests so you could
            enjoy your travel with a peace of mind. It is your responsibility to ensure
            the safety of your valuables. Contact our camp staff for any assistance
            required. Prior to departure, please check carefully that you have picked all
            of your belongings, camp67 will not be responsible for your belongings lost
            due to being left behind.
          </p>
          <strong>
            <li>Waste disposal</li>
          </strong>
          <p className="mt-2">
            At camp67, we endeavour to provide you the best of experience. To protect our
            environment and manage the waste efficiently, multiple garbage bins will be
            located across the camp site. We request you to dispose the garbage in the
            bins. We appreciate your cooperation in this mission.
          </p>
          <strong>
            <li>Sanitary facilities</li>
          </strong>
          <p className="mt-2">
            camp67 has well maintained hygienic facilities for your use. Please assist us
            in maintaining the standards and inform our staff should you experience any
            issues. Given the busy environment we recommend any Child under 10 be
            accompanied by a parent or guardian.
          </p>
          <strong>
            <li>Pets</li>
          </strong>
          <p className="mt-2">
            camp67 does not permit any pets to be allowed within the camp premises.
          </p>
          <strong>
            <li>Fire safety</li>
          </strong>
          <p className="mt-2">
            Open fires are prohibited within the camp67 precinct except the Bonfire area
            organised and managed by our staff.
          </p>
          <p className="mt-2">
            camp67 has a strict NO-SMOKING Policy across the entire precinct given the
            risk it presents to the camps and to all personnel within the camp precinct.
          </p>
          <p className="mt-2">
            In the event of a fire, everyone is obliged to follow the instructions of the
            Camp management and responsible officers if an evacuation is required. For
            your immediate safety, we have provided a Fire extinguisher at appropriate
            locations close to all tent areas. The locations of these fire extinguishers
            are highlighted in the Emergency Information Card available at our reception
            and within each tents. Our staff are happy to demonstrate how to use them in
            case of an emergency. Please contact our staff should you see any risk of fire
            in your area of residence.
          </p>
          <strong>
            <li>Open spaces and Common areas</li>
          </strong>
          <p className="mt-2">
            camp67 values your stay with us and has provided the camp site with dedicated
            open spaces and common areas for relaxing and interacting with friends and
            family. We seek your assistance in maintaining these areas by following any
            instructions provided. Please help us to maintain our open spaces in the most
            hygienic and safe manner. They&apos;re there for you.
          </p>
          <strong>
            <li>Children and minors</li>
          </strong>
          <p className="mt-2">
            camp67 attracts diverse range of guests who appreciate the relaxed atmosphere
            in our premises. Predominantly our guests stay with us to experience the Kumbh
            mela, participate in various related recreational activities, relaxation,
            cultural and yogic experiences.
          </p>
          <p className="mt-2">
            In the interest of our guests, we urge all parents/ guardians to care for
            their children and their activities. Kumbh Mela is an extremely crowded event,
            and we strongly recommend that you accompany your children at all times
            wherever they go for their safety and security purposes.
          </p>
          <strong>
            <li>Special conditions</li>
          </strong>
          <ol className="list-[lower-alpha] px-8 py-2">
            <li>
              Guest must not plug in any faulty devices as they may cause harm to yourself
              and has a potential of damaging the camp facilities.
            </li>
            <li>
              Guests must inform the camp67 employees about any special requirements e.g.
              any allergies in advance. camp67 shall endeavour to meet the requirements,
              however, cannot guarantee if it could meet all special requirements.
            </li>
            <li>
              Guests must follow the safety and security precautionary measures as
              prescribed by the camp67 staff and the local law enforcement agencies at all
              times.
            </li>
            <li>
              Consumption of any meat, seafood, poultry, eggs or products containing these
              are strictly prohibited within the camp67 site.
            </li>
            <li>
              Consumption of Alcohol, Drugs or any such addictive items are strictly
              prohibited within the camp67 site. Guests will be liable for their own
              actions or involvement in any illegal and inappropriate act or trade that
              are punishable under Indian statute.
            </li>
            <li>
              Guests must respect the privacy of all other guests staying at camp67 and
              ensure that they don&apos;t get involved in any activities which poses any
              inconvenience or danger to any guest or disrupts the serenity of the camp67
              site.
            </li>
          </ol>
          <p className="mt-2">
            camp67 management will use its best efforts to avoid any such incidents,
            however, reserves its rights to seek involvement of the local law enforcement
            agencies in serious cases.
          </p>
        </ol>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">8. Modifications to the Service</p>
        </strong>
        <p className="mt-2">
          camp67 reserves the right to modify or discontinue, temporarily or permanently,
          the Service (or any part thereof) with or without notice. You agree that camp67
          will not be liable to you for any modification, suspension, or discontinuance of
          the Service.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">9. Intellectual Property</p>
        </strong>
        <p className="mt-2">
          All content and materials provided on our Service are the property of camp67 or
          its content suppliers and are protected by intellectual property laws. You agree
          not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the
          Service without express written permission from us.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">10. Changes to Terms</p>
        </strong>
        <p className="mt-2">
          We may update these Terms from time to time. We will notify you of any changes
          by posting the new Terms on our website and updating the &lquot;effective
          date&rquot; at the top of these Terms. Your continued use of the Service after
          such changes constitutes your agreement to the new Terms.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">11. Governing Law</p>
        </strong>
        <p className="mt-2">
          These Terms shall be governed and construed in accordance with the laws of
          India, without regard to its conflict of law provisions.
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        <strong>
          <p className="mt-2">12. Contact Us</p>
        </strong>
        <p className="mt-2">
          For any questions about these Terms, please contact our{' '}
          <Link prefetch="intent" to="/support" className="underline">
            support team
          </Link>{' '}
          at:
          <br />
          <br />
          Email: support@camp67.com
          <br />
          Phone: +91 8860092457
          <br />
          <br />
          By accessing or using our Service, you agree to be bound by these Terms, our{' '}
          <Link prefetch="intent" to="/privacy-policy" className="underline">
            Privacy Policy
          </Link>
          ,{' '}
          <Link prefetch="intent" to="/payment-policy" className="underline">
            Payment Policy
          </Link>
          , and our{' '}
          <Link
            prefetch="intent"
            to="/cancellation-and-refund-policy"
            className="underline"
          >
            Cancellation & Refund Policy
          </Link>
          .
          <br />
          <br />
          Thank you for choosing camp67.
        </p>
      </div>
    </div>
  );
};

export const ResellerPolicyModal: React.FC<ResellerPolicyModalProps> = ({
  isOpen,
  onClose,
}) => {
  useEffect(() => {
    if (isOpen) {
      const bodyWidth = document.body.clientWidth;
      document.body.style.overflow = 'hidden';
      document.body.style.width = `${bodyWidth}px`;
    }
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.width = 'auto';
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white w-11/12 md:w-8/12 h-[90%] relative pt-20 pb-10 px-10 md:px-20 rounded-lg">
        <ModalCloseButton onClose={onClose} />
        <div className="overflow-y-auto h-full">
          <TermsOfService />
        </div>
      </div>
    </Modal>
  );
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  shouldCloseOnOutsideClick = true,
  shouldCloseOnEscKey = true,
}) => {
  const modalListener = useCallback(
    (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === 'modal' && shouldCloseOnOutsideClick) {
        onClose();
      }
    },
    [onClose, shouldCloseOnOutsideClick],
  );

  const keyboardListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && shouldCloseOnEscKey) {
        onClose();
      }
    },
    [onClose, shouldCloseOnEscKey],
  );

  useEffect(() => {
    const modal = document.getElementById('modal');

    if (isOpen) {
      modal?.addEventListener('click', modalListener);
      window.addEventListener('keydown', keyboardListener);
    }

    return () => {
      modal?.removeEventListener('click', modalListener);
      window.removeEventListener('keydown', keyboardListener);
    };
  }, [isOpen, modalListener, keyboardListener]);
  if (isOpen) {
    return (
      <dialog
        id="modal"
        open={isOpen}
        className="w-full h-full left-1/2 top-1/2 fixed -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center z-50 bg-black/65 animate-[fadeIn_0.3s]"
      >
        {children}
      </dialog>
    );
  }
  return null;
};

import { Types } from '@amplitude/analytics-browser';

import { proxy } from 'valtio';

type Analytics = { amplitude?: Types.NodeClient };

export enum AmplitudeEvent {
  SECTION_VIEW = 'sectionView',
  PAGE_VIEW = 'pageView',
  OPERATION_SUCCESS = 'operationSuccess',
  OPERATION_FAIL = 'operationFail',
  BUTTON_CLICK = 'buttonClick',
}

export enum ButtonTag {
  RESERVE_A_SPOT = 'reserveSpot',
  SUBMIT_OTP = 'submitOtp',
  ACCEPT_TANDC = 'acceptedTermsAndConditions',
  SUBMIT_RESERVATION = 'submitReservation',
  BOOK_A_ROOM = 'bookARoom',
  NEXT_STEP = 'nextStep',
  CHAT_WITH_US = 'chatWithUs',
  CHOOSE_ACCOMMODATION = 'chooseAccommodation',
  ADD_ACCOMMODATION = 'addAccommodation',
  SUBTRACT_ACCOMMODATION = 'subtractAccommodation',
  SEE_MORE_ACCOMMODATION = 'seeMoreAccommodation',
  HEAR_SOUNDS_OF_KUMBH = 'hearSoundsOfKumbh',
  PAUSE_SOUNDS_OF_KUMBH = 'pauseSoundsOfKumbh',
  SUBSCRIBE = 'subscribe',
  GO_TO_BOOKING = 'goToBooking',
  GO_HOME = 'goHome',
  OPEN_INQUIRY = 'openInquiry',
  SUBMIT_INQUIRY = 'submitInquiry',
  PLAY_TESTIMONIAL_MONTAGE = 'playTestimonialMontage',
  PLAY_TESTIMONIAL = 'playTestimonial',
  OPEN_FAQ = 'openFaq',
  NEXT_IMAGE = 'nextImage',
  PREV_IMAGE = 'prevImage',
  SELECT_RAZORPAY = 'selectRazorpay',
  SELECT_PAYPAL = 'selectPaypal',
}

export enum ModalSource {
  RESERVATION_SUCCESS_MODAl = 'reservationSuccessModal',
}

export enum ButtonSource {
  STEP_1 = 'reservationStep1',
  STEP_2 = 'reservationStep2',
  STEP_3 = 'reservationStep3',
  RESERVATION_GALLERY = 'reservationGallery',
}

export enum Operation {
  RESERVATION = 'reservation',
  SUBSCRIBE = 'subscribe',
  INQUIRE = 'inquire',
}

export const analyticsState = proxy<Analytics>({ amplitude: undefined });

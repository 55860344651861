import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import Sprite from '../sprites/sprite';
import { MessageKey } from '../locales/en';
import { FormattedString, useMessages } from '../i18n/useMessages';

export interface AmenityProps {
  summary: MessageKey;
  detail: React.ReactNode;
  open?: boolean;
  isCollapsible?: boolean;
  appearance?: 'normal' | 'in-room' | 'camp' | 'dialog';
  background?: 'light' | 'gray';
}

export const amenities: AmenityProps[] = [
  {
    summary: 'camp67-accommodation-and-amenities.luxurious-title',
    detail: (
      <FormattedString message={'camp67-accommodation-and-amenities.luxurious-detail'} />
    ),
    open: true,
    isCollapsible: false,
  },
  {
    summary: 'camp67-accommodation-and-amenities.all-in-one-city-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.all-in-one-city-detail'}
      />
    ),
    open: true,
    isCollapsible: false,
  },
  {
    summary: 'camp67-accommodation-and-amenities.heater-services-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.heater-services-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.cafe-central-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.cafe-central-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.daily-housekeeping-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.daily-housekeeping-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.bottled-water-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.bottled-water-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.natural-gardens-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.natural-gardens-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.ayurvedic-products-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.ayurvedic-products-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.minutes-from-ganges-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.minutes-from-ganges-detail'}
      />
    ),
  },
  {
    summary: 'camp67-accommodation-and-amenities.yoga-and-meditation-title',
    detail: (
      <FormattedString
        message={'camp67-accommodation-and-amenities.yoga-and-meditation-detail'}
      />
    ),
  },
];

export const Amenity: React.FC<AmenityProps> = ({
  summary: summaryKey,
  detail,
  open = false,
  isCollapsible = true,
  appearance = 'normal',
  background = 'gray',
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  const isDialog = appearance === 'dialog';

  const isGrayBg = background === 'gray';

  const getMessage = useMessages();
  const summary = getMessage(summaryKey);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <details
      className={clsx(
        'flex flex-row text-left justify-between w-full rounded-md px-8 md:px-8 select-none list-none cursor-pointer',
        {
          'py-9': !isCollapsible,
          'py-4': isCollapsible,
          // [styles.amenityInRoom]: appearance === 'in-room',
          // [styles.amenityCamp]: appearance === 'camp',
          ['px-8 md:px-8']: isDialog,
          'bg-gray-100 hover:bg-orange-100 transition-all duration-500 ease-in-out':
            isGrayBg,
          'bg-white hover:bg-orange-100 transition-all duration-500 ease-in-out':
            !isGrayBg,
        },
      )}
      open={open || isOpen}
      id={`collapse-${summary}`}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <summary
        // className={clsx(styles.summary, { textXs: isDialog, textSm: !isDialog }, 'black')}
        className={clsx(
          'font-sans uppercase tracking-wider font-medium flex flex-row items-center justify-between',
          {
            'text-lg leading-2 xl:leading-8': !isCollapsible,
          },
          { 'text-base leading-2 xl:leading-8': isCollapsible },
        )}
      >
        {summary}
        {isCollapsible ? (
          <Sprite
            width="56"
            height="30"
            name="down"
            strokeWidth="6"
            onClick={toggleOpen}
            className={clsx('w-4 stroke-black cursor-pointer', {
              '-rotate-180 transition': open || isOpen,
            })}
          />
        ) : null}
      </summary>
      <div
        className={clsx('mt-2', {
          'pb-4': isCollapsible,
          ['']: isDialog,
        })}
      >
        {detail}
      </div>
    </details>
  );
};
